<template>
    <aside class="sidebar" data-sidebar>
        <div class="sidebar-info">
            <figure class="avatar-box">
                <img :src="getImagePathWJ(profileData.logo)" :alt="profileData.name" width="80">
            </figure>
            <div class="info-content">
                <h1 id="user-name" class="name">{{ profileData.name }}</h1>
                <p id="title" class="title">{{ profileData.title }}</p>
            </div>
            <button class="info_more-btn" data-sidebar-btn @click="toggleContacts">
                <span>Show Contacts</span>
                <ion-icon name="chevron-down"></ion-icon>
            </button>
        </div>
        <div class="sidebar-info_more" v-if="showContacts">
            <div class="separator"></div>
            <ul class="contacts-list">

                <li class="contact-item">
                    <div class="icon-box">
                        <ion-icon name="mail-outline"></ion-icon>
                    </div>
                    <div class="contact-info">
                        <p class="contact-title">Email</p>
                        <a class="contact-link" :href="'mailto:' + profileData.email" :title="profileData.email">{{
                            profileData.email }}</a>

                    </div>
                </li>

                <li class="contact-item">
                    <div class="icon-box">
                        <ion-icon name="logo-linkedin"></ion-icon>
                    </div>
                    <div class="contact-info">
                        <p class="contact-title">LinkedIn</p>
                        <a class="contact-link" :href="profileData.linkedin" :title="profileData.linkedin"
                            target="_blank">{{ profileData.linkedin }}</a>

                    </div>
                </li>

                <li class="contact-item">
                    <div class="icon-box">
                        <ion-icon name="logo-github"></ion-icon>
                    </div>
                    <div class="contact-info">
                        <p class="contact-title">Github</p>
                        <a class="contact-link" :href="profileData.github" :title="profileData.github"
                            target="_blank">{{ profileData.github }}</a>
                    </div>
                </li>

                <li class="contact-item">
                    <div class="icon-box">
                        <ion-icon name="git-branch-outline"></ion-icon>
                    </div>
                    <div class="contact-info">
                        <p class="contact-title">Page Version</p>
                        <p class="contact-link" :title="version">{{ version }}</p>
                    </div>
                </li>

                <li class="contact-item">
                    <div class="icon-box">
                        <ion-icon name="code-outline"></ion-icon>
                    </div>
                    <div class="contact-info">
                        <p class="contact-title">Crafted with</p>
                        <p class="contact-link" :title="`Vue.Js`">Vue.Js</p>
                    </div>
                </li>
            </ul>
            <div class="separator"></div>
        </div>
    </aside>
</template>

<script>
import pkg from '../../package.json';
import { getImagePathWJ } from '@/utils/imageLoader.js';

export default {
    data() {
        return {
            version: pkg.version
        };
    },
    props: {
        profileData: Object,
        showContacts: Boolean,
        toggleContacts: Function
    }, methods: {
        getImagePathWJ
    }
}
</script>

<style scoped>
/* Add styles for sidebar here */
</style>
