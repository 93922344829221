<template>
    <article class="resume">
        <header>
            <h2 class="h2 article-title">Professional History</h2>
        </header>
        <section class="timeline">
            <div class="title-wrapper">
                <div class="icon-box">
                    <ion-icon name="briefcase-outline"></ion-icon>
                </div>
                <h3 class="h3">Experience</h3>
            </div>
            <ol class="timeline-list" id="timeline-list-experience-data">
                <li class="timeline-item" v-for="exp in experienceData" :key="exp">
                    <div class="timeline-header">
                        <!-- <h4 class="h4 timeline-item-title">Tiong Nam Logistics Holdings Berhad</h4> -->
                        <div class="company-info">
                            <h4 class="h4 timeline-item-title">{{ exp.company }}</h4>
                            <a :href="exp.link" target="_blank">
                                <img class="company-icon" :src="getImagePathCompany(exp.logo)" :alt="exp.company">
                            </a>
                        </div>
                        <span class="timeline-date">{{ exp.duration }}</span>
                    </div>
                    <span style="font-weight: bold;">{{ exp.position }}</span>
                    <ul>
                        <li class="timeline-text" v-for="(rep, index) in exp.responsibilities" :key="`exp-${index}`">
                            <p class="timeline-text">
                                {{ rep }}
                            </p>

                        </li>
                    </ul>
                </li>
            </ol>
        </section>
        <section class="timeline">
            <div class="title-wrapper">
                <div class="icon-box">
                    <ion-icon name="book-outline"></ion-icon>
                </div>
                <h3 class="h3">Education</h3>
            </div>
            <ol class="timeline-list" id="timeline-list-experience-data">
                <li class="timeline-item" v-for="edu in educationData" :key="edu">
                    <div class="timeline-header">
                        <!-- <h4 class="h4 timeline-item-title">Tiong Nam Logistics Holdings Berhad</h4> -->
                        <div class="company-info">
                            <h4 class="h4 timeline-item-title">{{ edu.place }}</h4>
                            <a :href="edu.link" target="_blank">
                                <img class="company-icon" :src="getImagePathCompany(edu.logo)" :alt="edu.place">
                            </a>
                        </div>
                        <span class="timeline-date">{{ edu.duration }}</span>
                    </div>
                    <span style="font-weight: bold;">{{ edu.course }}</span>
                    <p class="timeline-text">
                        {{ edu.description }}
                    </p>
                </li>
            </ol>
        </section>
    </article>
</template>

<script>
import { getImagePathCompany } from '@/utils/imageLoader.js';

export default {
    props: {
        experienceData: Array,
        educationData: Array
    }, methods: {
        getImagePathCompany
    }
}
</script>

<style scoped>
/* Add styles for Professional History section here */
</style>
