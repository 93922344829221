<template>
    <main>
        <!-- Show Sidebar only if activeNav is not 'Playground' -->
        <Sidebar v-if="dataLoaded && activeNav !== 'Playground'" :profileData="data" :showContacts="showContacts"
            :toggleContacts="toggleContacts" />

        <div class="main-content">
            <nav class="navbar">
                <ul class="navbar-list">
                    <li class="navbar-item" v-for="item in navItems" :key="item.name">
                        <button class="navbar-link" :class="{ active: activeNav === item.name }"
                            @click="setActiveNav(item.name)">
                            {{ item.name }}
                        </button>
                    </li>
                </ul>
            </nav>

            <PlayGround v-if="activeNav === 'Playground'" :aboutText="data.about" :services="data.generalSkill"
                :techSkills="tech" />
            <About v-if="activeNav === 'About'" :aboutText="data.about" :services="data.generalSkill"
                :techSkills="tech" />
            <ProfessionalHistory v-if="activeNav === 'Professional History'" :experienceData="data.experience"
                :educationData="data.education" />
        </div>
    </main>
</template>


<script>
import Sidebar from './SidebarVal.vue';
import About from './AboutMe.vue';
import ProfessionalHistory from './ProfessionalHistory.vue';
import PlayGround from './PlayGround.vue';

export default {
    components: {
        Sidebar,
        About,
        ProfessionalHistory,
        PlayGround
    },
    data() {
        return {
            data: {}, // Will hold profile data
            navItems: [], // Will hold navigation items from settings
            showContacts: true,
            activeNav: 'About', // Default active navigation section
            dataLoaded: false, // Flag to indicate data loading state
            showModal: false,
            modalImage: '',
            modalTitle: '',
            modalText: '',
            tech: []
        };
    },
    async created() {
        try {
            // Fetch both JSON data files concurrently
            const timestamp = new Date().getTime();//use time stamp to renew json
            const [profileResponse, settingsResponse, techResponse] = await Promise.all([
                fetch(`/json/profile.json?t=${timestamp}`),
                fetch(`/json/settings.json?t=${timestamp}`),
                fetch(`/json/tech.json?t=${timestamp}`)
            ]);

            // Check if the responses are valid
            if (!profileResponse.ok) throw new Error('Failed to fetch profile data');
            if (!settingsResponse.ok) throw new Error('Failed to fetch settings data');
            if (!techResponse.ok) throw new Error('Failed to fetch settings data');

            // Parse the JSON responses
            const [profileData, settingsData, techData] = await Promise.all([
                profileResponse.json(),
                settingsResponse.json(),
                techResponse.json()
            ]);

            // Update component data
            this.data = profileData;
            this.navItems = settingsData.navItems;
            this.tech = techData;
            this.dataLoaded = true;
        } catch (error) {
            console.error('Error fetching JSON data:', error);
            // You could set an error state here or display an alert/notification.
        }
    },
    methods: {
        toggleContacts() {
            this.showContacts = !this.showContacts;
        },
        setActiveNav(nav) {
            this.activeNav = nav;
        },
        closeModal() {
            this.showModal = false;
        }
    }
}
</script>

<style scoped>
@import '../assets/css/styles.css';
@import '../assets/css/responsive.css';
/* Import your CSS styles */
</style>
